import { FC } from "react";
import styles from "./MenuBar.module.scss";
import menuStyles from "../styles/menu.module.scss";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { UndoIcon } from "../views/icons/UndoIcon";
import { RedoIcon } from "../views/icons/RedoIcon";
import { EllipsisIcon } from "../views/icons/EllipsisIcon";
import { cx } from "../lib/cx";
import { useTexts } from "../helpers/texts";
import { Tooltip } from "../views/Tooltip";

const textMap = {
  undo: {
    en: "Undo",
    ja: "元に戻す",
  },
  redo: {
    en: "Redo",
    ja: "やり直す",
  },
  "export to clipboard": {
    en: "Export to clipboard",
    ja: "クリップボードに書き出し",
  },
  "export to file": {
    en: "Export to file",
    ja: "ファイルに書き出し",
  },
  "zoom in": {
    en: "Zoom in",
    ja: "ズームイン",
  },
  "zoom out": {
    en: "Zoom out",
    ja: "ズームアウト",
  },
  "zoom to 100%": {
    en: "Zoom to 100%",
    ja: "100% にズーム",
  },
  "select all": {
    en: "Select all",
    ja: "すべて選択",
  },
};

export type MenuBarMenuItem =
  | "export_to_clipboard"
  | "export_to_file"
  | "select_all"
  | "zoom_in"
  | "zoom_out"
  | "zoom_to_100";

interface Props {
  canUndo: boolean;
  canRedo: boolean;
  disabled?: boolean;
  onUndoClick: () => void;
  onRedoClick: () => void;
  onMenuItemSelect: (item: MenuBarMenuItem) => void;
}

export const MenuBar: FC<Props> = (props) => {
  const texts = useTexts(textMap);

  return (
    <div className={styles.container}>
      <div className={styles.historyButtons}>
        <Tooltip label={texts.undo} shortcut="⌘Z">
          <button
            disabled={props.disabled || !props.canUndo}
            className={cx(styles.button, styles.undo)}
            onClick={props.onUndoClick}
          >
            <UndoIcon />
          </button>
        </Tooltip>
        <Tooltip label={texts.redo} shortcut="⇧⌘Z">
          <button
            disabled={props.disabled || !props.canRedo}
            className={cx(styles.button, styles.redo)}
            onClick={props.onRedoClick}
          >
            <RedoIcon />
          </button>
        </Tooltip>
      </div>
      <div className={styles.menuWrapper}>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            disabled={props.disabled}
            className={cx(styles.button, styles.menu)}
          >
            <EllipsisIcon />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              side="bottom"
              sideOffset={8}
              align="end"
              className={menuStyles.content}
            >
              <DropdownMenu.Item
                className={menuStyles.item}
                onSelect={() => props.onMenuItemSelect("export_to_clipboard")}
              >
                {texts["export to clipboard"]}
                <span className={menuStyles.shortcut}>⇧⌘C</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={menuStyles.item}
                onSelect={() => props.onMenuItemSelect("export_to_file")}
              >
                {texts["export to file"]}
              </DropdownMenu.Item>
              <DropdownMenu.Separator className={menuStyles.separator} />
              <DropdownMenu.Item
                className={menuStyles.item}
                onSelect={() => props.onMenuItemSelect("zoom_in")}
              >
                {texts["zoom in"]}
                <span className={menuStyles.shortcut}>⌘+</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={menuStyles.item}
                onSelect={() => props.onMenuItemSelect("zoom_out")}
              >
                {texts["zoom out"]}
                <span className={menuStyles.shortcut}>⌘-</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={menuStyles.item}
                onSelect={() => props.onMenuItemSelect("zoom_to_100")}
              >
                {texts["zoom to 100%"]}
                <span className={menuStyles.shortcut}>⌘0</span>
              </DropdownMenu.Item>
              <DropdownMenu.Separator className={menuStyles.separator} />
              <DropdownMenu.Item
                className={menuStyles.item}
                onSelect={() => props.onMenuItemSelect("select_all")}
              >
                {texts["select all"]}
                <span className={menuStyles.shortcut}>⌘A</span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};
