import styles from "./PixiCanvas.module.scss";
import {
  FC,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Application,
  BLEND_MODES,
  BlurFilter,
  Container as PixiContainer,
  Graphics as PixiGraphics,
  LINE_CAP,
  LINE_JOIN,
  Matrix,
  MSAA_QUALITY,
  Renderer,
  RenderTexture,
  SCALE_MODES,
  Sprite as PixiSprite,
  TextStyle,
  Texture,
} from "pixi.js";
import { Container, Sprite, Stage, Text, useApp } from "@pixi/react";
import { Item } from "../model/item";
import { Offset } from "../model/offset";
import { PixelateFilter } from "@pixi/filter-pixelate";
import { setPngDpi } from "../lib/png-dpi";
import { RectInk } from "../model/tool/rect";
import { measureTextInk, TextInk, textStyleForPixi } from "../model/tool/text";
import { ImageInk } from "../model/tool/image";
import { boundInk, FilterInk } from "../model/tool";
import { PenInk } from "../model/tool/pen";
import { normalizeBox } from "../model/box";
import { ArrowInk } from "../model/tool/arrow";
import { DropShadowFilter } from "@pixi/filter-drop-shadow";
import { NumberInk, numberInkSize } from "../model/tool/number";
import { applySize } from "../model/style";

export interface PixiCanvasRef {
  exportPng: () => Promise<Blob>;
}

interface Props {
  items: Item[];
  pan: Offset;
  zoom: number;
}

export const PixiCanvas = forwardRef<PixiCanvasRef, Props>(function PixiCanvas(props, ref) {
  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<PixiContainer>(null);
  const [app, setApp] = useState<Application>();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      exportPng: async () => {
        const container = containerRef.current;
        if (app === undefined || container === null) {
          throw new Error("Pixi app is not ready");
        }
        return await exportPng(app, container);
      },
    }),
    [app],
  );

  useLayoutEffect(() => {
    const div = divRef.current;
    if (div === null) {
      return;
    }
    const observer = new ResizeObserver((e) => {
      const rect = e[0].target.getBoundingClientRect();
      setWidth(rect.width);
      setHeight(rect.height);
    });
    observer.observe(div);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={divRef} className={styles.container}>
      <Stage
        raf={false}
        width={width}
        height={height}
        options={{ backgroundAlpha: 0 }}
        onMount={setApp}
      >
        {/*<Sprite*/}
        {/*  image="https://pixijs.io/pixi-react/img/bunny.png"*/}
        {/*  x={width / 2}*/}
        {/*  y={height / 2}*/}
        {/*  anchor={{ x: 0.5, y: 0.5 }}*/}
        {/*/>*/}
        <Container
          ref={containerRef}
          name="main"
          x={-props.pan.deltaX * props.zoom}
          y={-props.pan.deltaY * props.zoom}
          scale={props.zoom}
        >
          {props.items.map((item) =>
            item.ink.type === "image" ? (
              <ImagePixi key={item.id} ink={item.ink} itemId={item.id} allItems={props.items} />
            ) : item.ink.type === "rect" ? (
              <RectPixi key={item.id} ink={item.ink} />
            ) : item.ink.type === "text" ? (
              <TextPixi key={item.id} ink={item.ink} />
            ) : item.ink.type === "pen" ? (
              <PenPixi key={item.id} ink={item.ink} />
            ) : item.ink.type === "arrow" ? (
              <ArrowPixi key={item.id} ink={item.ink} />
            ) : item.ink.type === "number" ? (
              <NumberPixi key={item.id} items={props.items} ink={item.ink} />
            ) : null,
          )}
        </Container>
      </Stage>
    </div>
  );
});

const colors = {
  pink: "#ff00ff",
  red: "#ff0066",
  orange: "#ffcc00",
  green: "#00ff00",
  cyan: "#00ccff",
  purple: "#6600ff",
  white: "#ffffff",
  black: "#000000",
};

const textColors = {
  pink: "#ff00ff",
  red: "#ff0066",
  orange: "#ffcc00",
  green: "#00cc00",
  cyan: "#00ccff",
  purple: "#6600ff",
  white: "#000000",
  black: "#000000",
};

async function exportPng(app: Application, container: PixiContainer): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const canvas = app.renderer.extract.canvas(container);
    if (canvas.toBlob === undefined) {
      reject("Failed to export PNG: toBlob is undefined");
      return;
    }
    canvas.toBlob(async (blob) => {
      if (blob === null) {
        reject("Failed to export PNG: blob is null");
        return;
      }
      // Google Chrome では PNG 画像をクリップボードにコピーする際にサニタイズによってメタデータが
      // 削除されるため、pHYs チャンクはコピー時に失われる。
      // https://issues.chromium.org/issues/40853606
      const pngWithPhys = setPngDpi(await blob.arrayBuffer(), {
        x: 72 * window.devicePixelRatio,
        y: 72 * window.devicePixelRatio,
      });
      resolve(new Blob([pngWithPhys], { type: "image/png" }));
    });
  });
}

// TODO: 個別に blur, pixelate を適用する関数を書いてもいいかも。
// そして Image は Blur と Pixelate に強く依存するのだから、同じモジュールにしてもいいかも
// Blur は Image が存在しないと意味ないし、Image は Blur の存在を仮定するので
const ImagePixi: FC<{
  ink: ImageInk;
  itemId: string;
  allItems: Item[];
}> = (props) => {
  const app = useApp();

  const overlayFilterInks: FilterInk[] = useMemo(() => {
    const index = props.allItems.findIndex((it) => it.id === props.itemId);
    if (index < 0) {
      return [];
    }
    return props.allItems
      .slice(index + 1)
      .map((it) => it.ink)
      .filter((it) => it.kind === "filter");
  }, [props.allItems, props.itemId]);

  const texture = useMemo(() => {
    const original = PixiSprite.from(props.ink.bitmap);
    const container = new PixiContainer();
    container.addChild(original);

    overlayFilterInks.forEach((ink) => {
      const filtered = getFilteredSprite(props.ink.bitmap, ink);
      if (filtered === undefined) {
        return;
      }

      const maskBox = normalizeBox(boundInk(ink));
      const mask = new PixiGraphics();
      const imageScaleX = props.ink.width / props.ink.bitmap.width;
      const imageScaleY = props.ink.height / props.ink.bitmap.height;
      mask.beginFill(0x000000);
      mask.drawRect(
        Math.round((maskBox.x - props.ink.x) / imageScaleX),
        Math.round((maskBox.y - props.ink.y) / imageScaleY),
        Math.round(maskBox.width / imageScaleX),
        Math.round(maskBox.height / imageScaleY),
      );
      mask.endFill();

      const filteredContainer = new PixiContainer();
      filteredContainer.addChild(filtered);
      filteredContainer.mask = mask;

      const eraser = mask.clone();
      eraser.blendMode = BLEND_MODES.ERASE;

      container.addChild(eraser);
      container.addChild(filteredContainer);
    });

    const rt = RenderTexture.create({
      width: props.ink.bitmap.width,
      height: props.ink.bitmap.height,
      scaleMode: SCALE_MODES.NEAREST,
    });
    app.renderer.render(container, { renderTexture: rt });
    return rt;
  }, [props.ink, overlayFilterInks]);

  return (
    <Sprite
      x={props.ink.x}
      y={props.ink.y}
      width={props.ink.width}
      height={props.ink.height}
      texture={texture}
    />
  );
};

const blurSize = 16;

const pixelateSize = 10;

function getFilteredSprite(bitmap: ImageBitmap, ink: FilterInk): PixiSprite | undefined {
  if (ink.type === "blur") {
    const filter = new BlurFilter(applySize(blurSize, ink.size), applySize(blurSize, ink.size) / 2);
    const filtered = PixiSprite.from(bitmap);
    filtered.filters = [filter];
    return filtered;
  }
  if (ink.type === "pixelate") {
    const filter = new PixelateFilter(applySize(pixelateSize, ink.size));
    const filtered = PixiSprite.from(bitmap);
    filtered.filters = [filter];
    return filtered;
  }
  if (ink.type === "redact") {
    const data = new Uint8Array(0);
    const texture = Texture.fromBuffer(data, bitmap.width, bitmap.height);
    return PixiSprite.from(texture);
  }
  return undefined;
}

const rectThickness = 6;

const RectPixi: FC<{ ink: RectInk }> = (props) => {
  const app = useApp();

  const sprite = useMemo(() => {
    const box = normalizeBox(props.ink);
    return renderGraphics(app.renderer as Renderer, (g) => {
      g.lineStyle(applySize(rectThickness, props.ink.style.size), colors[props.ink.style.color]);
      g.drawRoundedRect(box.x, box.y, box.width, box.height, 1);
    });
  }, [app, props.ink]);

  return <Sprite x={sprite.x} y={sprite.y} texture={sprite.texture} />;
};

const TextPixi: FC<{
  ink: TextInk;
}> = (props) => {
  const offset = useMemo(() => {
    if (props.ink.width === undefined) {
      return 0;
    }
    const matrix = measureTextInk(props.ink);
    switch (props.ink.align) {
      case "left":
        return 0;
      case "center":
        return (props.ink.width - matrix.width) / 2;
      case "right":
        return props.ink.width - matrix.width;
    }
  }, [props.ink]);

  const textStyle = useMemo(() => {
    const style = textStyleForPixi(props.ink);
    style.fill = textColors[props.ink.style.color];
    return style;
  }, [props.ink]);

  return <Text x={props.ink.x + offset} y={props.ink.y} text={props.ink.text} style={textStyle} />;
};

const penThickness = 8;

const PenPixi: FC<{ ink: PenInk }> = (props) => {
  const app = useApp();

  const sprite = useMemo(() => {
    return renderGraphics(app.renderer as Renderer, (g) => {
      const points = props.ink.points;
      if (points.length < 2) {
        if (points.length === 1) {
          g.beginFill(colors[props.ink.style.color]);
          g.drawCircle(points[0].x, points[0].y, applySize(penThickness, props.ink.style.size) / 2);
          g.endFill();
        }
        return g;
      }

      g.lineStyle({
        width: applySize(penThickness, props.ink.style.size),
        color: colors[props.ink.style.color],
        cap: LINE_CAP.ROUND,
        join: LINE_JOIN.ROUND,
      });
      g.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < points.length - 2; i++) {
        const cpX = (points[i].x + points[i + 1].x) / 2;
        const cpY = (points[i].y + points[i + 1].y) / 2;
        g.quadraticCurveTo(points[i].x, points[i].y, cpX, cpY);
      }

      // 最後の2点で曲線を終える
      const last = points.length - 1;
      g.quadraticCurveTo(points[last - 1].x, points[last - 1].y, points[last].x, points[last].y);
    });
  }, [app, props.ink]);

  return <Sprite x={sprite.x} y={sprite.y} texture={sprite.texture} />;
};

const arrowThicknesses = 16;

const ArrowPixi: FC<{ ink: ArrowInk }> = (props) => {
  const app = useApp();

  const texture = useMemo(() => {
    const dx = props.ink.to.x - props.ink.from.x;
    const dy = props.ink.to.y - props.ink.from.y;
    const length = Math.sqrt(dx * dx + dy * dy);
    let thickness = applySize(arrowThicknesses, props.ink.style.size);
    const threshold = thickness * 6; // triangleSize * 2
    if (length < threshold) {
      thickness *= length / threshold;
    }
    const triangleSize = thickness * 3;
    const triangleHeight = triangleSize * Math.sin(Math.PI / 3);

    // Graphics の rotation などでは graphics.getLocalBounds に影響を与えられないので、
    // Texture にレンダーする際に見切れてしまう。そのため頂点座標そのものを移動させる。
    const matrix = new Matrix()
      .rotate(Math.atan2(dy, dx))
      .translate(props.ink.from.x, props.ink.from.y);

    return renderGraphics(app.renderer as Renderer, (g) => {
      g.beginFill(colors[props.ink.style.color]);
      g.drawPolygon([
        matrix.apply({ x: 0, y: -1 }),
        matrix.apply({ x: length - triangleHeight, y: -thickness / 2 }),
        matrix.apply({ x: length - triangleHeight, y: -triangleSize / 2 }),
        matrix.apply({ x: length, y: 0 }),
        matrix.apply({ x: length - triangleHeight, y: triangleSize / 2 }),
        matrix.apply({ x: length - triangleHeight, y: thickness / 2 }),
        matrix.apply({ x: 0, y: 1 }),
      ]);
      g.endFill();
    });
  }, [props.ink, app]);

  return <Sprite x={texture.x} y={texture.y} texture={texture.texture} />;
};

const NumberPixi: FC<{ items: Item[]; ink: NumberInk }> = (props) => {
  const app = useApp();

  const n = useMemo(() => {
    return (
      props.items.filter((it) => it.ink.type === "number" && it.ink.ts < props.ink.ts).length + 1
    );
  }, [props.items, props.ink]);

  const textStyle = useMemo(() => {
    const fontSize = applySize(numberInkSize, props.ink.style.size);
    return new TextStyle({
      fontSize: fontSize,
      fill: 0xffffff,
      fontWeight: "bold",
      dropShadow: true,
      dropShadowAlpha: 0.2,
      dropShadowBlur: 10,
      dropShadowDistance: 0,
      lineHeight: fontSize * 1.6,
      padding: fontSize / 2,
      lineJoin: "round",
    });
  }, [props.ink.style.size]);

  const sprite = useMemo(() => {
    return renderGraphics(app.renderer as Renderer, (g) => {
      g.lineStyle(applySize(numberInkSize, props.ink.style.size) * 0.15, 0xffffff);
      g.beginFill(textColors[props.ink.style.color]);
      g.drawCircle(
        props.ink.pos.x,
        props.ink.pos.y,
        applySize(numberInkSize, props.ink.style.size) * 0.8,
      );
      g.endFill();
    });
  }, [props.ink.style.color, props.ink.style.size, props.ink.pos]);

  return (
    <Container>
      <Sprite x={sprite.x} y={sprite.y} texture={sprite.texture} />
      <Text
        x={props.ink.pos.x}
        y={props.ink.pos.y}
        anchor={0.5}
        text={n.toString()}
        style={textStyle}
      />
    </Container>
  );
};

function renderGraphics(renderer: Renderer, render: (g: PixiGraphics) => void): PixiSprite {
  const g = new PixiGraphics();
  render(g);

  const { width, height, x, y } = g.getLocalBounds();

  const renderTexture = RenderTexture.create({
    width: Math.max(1, width),
    height: Math.max(1, height),
    multisample: MSAA_QUALITY.HIGH,
    resolution: window.devicePixelRatio,
  });

  renderer.render(g, {
    renderTexture,
    transform: new Matrix().translate(-x, -y),
  });

  // Required for MSAA, WebGL 2 only
  renderer.framebuffer.blit();

  g.destroy(true);

  const sprite = PixiSprite.from(renderTexture);
  sprite.x = x;
  sprite.y = y;

  try {
    return renderDropShadow(renderer, sprite);
  } finally {
    sprite.destroy(true);
  }
}

function renderDropShadow(renderer: Renderer, container: PixiContainer): PixiSprite {
  const padding = 10;
  const renderTexture = RenderTexture.create({
    width: Math.max(1, container.width + padding * 2),
    height: Math.max(1, container.height + padding * 2),
    multisample: MSAA_QUALITY.HIGH,
    resolution: window.devicePixelRatio,
  });

  const filter = new DropShadowFilter({
    color: 0x000000,
    alpha: 0.05,
    offset: { x: 0, y: 0 },
    blur: 1,
    quality: 8,
    resolution: window.devicePixelRatio,
  });
  filter.padding = padding;
  container.filters = [filter];

  renderer.render(container, {
    renderTexture,
    transform: new Matrix().translate(-container.x + padding, -container.y + padding),
  });

  // Required for MSAA, WebGL 2 only
  renderer.framebuffer.blit();

  const sprite = PixiSprite.from(renderTexture);
  sprite.x = container.x - padding;
  sprite.y = container.y - padding;

  return sprite;
}
