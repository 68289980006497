import { FC, ReactNode } from "react";
import styles from "./ItemContextMenu.module.scss";
import menuStyles from "../styles/menu.module.scss";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { useTexts } from "../helpers/texts";

const textMap = {
  delete: {
    en: "Delete",
    ja: "削除",
  },
  "bring to front": {
    en: "Bring to front",
    ja: "最前面に移動",
  },
  "bring forward": {
    en: "Bring forward",
    ja: "前面に移動",
  },
  "send backward": {
    en: "Send backward",
    ja: "背面に移動",
  },
  "send to back": {
    en: "Send to back",
    ja: "最背面に移動",
  },
};

export type ItemContextMenuItem =
  | "delete"
  | "bring_to_front"
  | "bring_forward"
  | "send_backward"
  | "send_to_back";

interface Props {
  children: ReactNode;
  onOpenChange?: (open: boolean) => void;
  onMenuItemSelect: (item: ItemContextMenuItem) => void;
}

export const ItemContextMenu: FC<Props> = (props) => {
  const texts = useTexts(textMap);

  return (
    <ContextMenu.Root onOpenChange={props.onOpenChange}>
      <ContextMenu.Trigger asChild>{props.children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className={menuStyles.content}>
          <ContextMenu.Item
            className={menuStyles.item}
            onClick={() => props.onMenuItemSelect("delete")}
          >
            {texts["delete"]}
            <span className={menuStyles.shortcut}>⌫</span>
          </ContextMenu.Item>
          <ContextMenu.Separator className={menuStyles.separator} />
          <ContextMenu.Item
            className={menuStyles.item}
            onClick={() => props.onMenuItemSelect("bring_to_front")}
          >
            {texts["bring to front"]}
            <span className={menuStyles.shortcut}>]</span>
          </ContextMenu.Item>
          <ContextMenu.Item
            className={menuStyles.item}
            onClick={() => props.onMenuItemSelect("bring_forward")}
          >
            {texts["bring forward"]}
            <span className={menuStyles.shortcut}>⌘ ]</span>
          </ContextMenu.Item>
          <ContextMenu.Item
            className={menuStyles.item}
            onClick={() => props.onMenuItemSelect("send_backward")}
          >
            {texts["send backward"]}
            <span className={menuStyles.shortcut}>⌘ [</span>
          </ContextMenu.Item>
          <ContextMenu.Item
            className={menuStyles.item}
            onClick={() => props.onMenuItemSelect("send_to_back")}
          >
            {texts["send to back"]}
            <span className={menuStyles.shortcut}>[</span>
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};
